import { useState } from 'react'
import { CustomFileUpload, CustomTextField } from "@components/shared-ui-components/CustomFields";
import { ErrorHelper as ErrorHelperWrapper } from '@components/shared-ui-components/CustomFields';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { Box, Button } from '@mui/material'
import { performRelevanceDataSelectionQueryData } from '@services/dashboardService';
import { useParams } from 'react-router-dom';


function UploadQueryData() {

  const [files, setFiles] = useState<File[]>([]);
  const { projectId, project_type } = useParams();

  const handleFormSubmit = (values: any, actions: FormikHelpers<any>) => {
    console.log('Handling form submit')
    // const actionAfterCall = getActionAfterSubmit(page, projectContextInfo);
    // Calling the api
    performRelevanceDataSelectionQueryData({...values, project_id: projectId, project_type})
      .then((res: any) => {
        // Reset the form after success
        actions.resetForm()
        // OVER HERE THE NEW ONE WE WILL HAVE TO HANDLE 

        // Enable the form
        actions.setSubmitting(false)
        // actionAfterCall(res, values);

        console.log('values in process response ====>', values, res)
        // show a notification progress has been save
      }).catch((e: any) => {
        actions.setSubmitting(false);
      })
  }

  const formik: FormikProps<any> = useFormik({
    initialValues: { compressed_file: null },
    onSubmit: handleFormSubmit,
  })

  const { errors, handleSubmit: handleSub, isSubmitting, touched } = formik || {}

  const handleSetFiles = (fs: File[]) => {
    setFiles(fs);
    formik.setFieldValue('compressed_file', fs[0])
  }

  const handleUrl = (url: string) => {
    files.length > 0 && setFiles([]);
    formik.setFieldValue('compressed_file', url)
  }

  return (
    <>
      <h4>Upload Query Data</h4>
      <form noValidate onSubmit={handleSub} style={{ display: 'inline-grid' }}>
        <Box>
          <CustomFileUpload files={files} setFiles={handleSetFiles} />
          <ErrorHelperWrapper isError={!!(touched.compressed_file && errors.compressed_file)} errorMsg={errors.compressed_file} helperId='compressed_file-create-project' />
          <input id="file" name="compressed_file" type="file" hidden />
          <h4>Or</h4>
          <CustomTextField
            placeholder="Input File link"
            sx={{ mb: 2 }}
            fullWidth
            name="compressed_file"
            value={files.length === 0 ? formik.values.compressed_file : ''}
            onChange={(e) => handleUrl(e.target.value)}
          />
          <Box sx={{ mb: 2 }}>
            <div>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                sx={{ mt: 1, mr: 1 }}
              >
                {`Upload`}
              </Button>

            </div>
          </Box>
        </Box>
      </form>
    </>
  )
}

export default UploadQueryData