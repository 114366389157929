import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FormikHelpers, useFormik } from 'formik';
import { callAuthFormApi, authFormValidationSchema, getAuthFormInitialValues, getActionAfterSubmit } from './helper';
import AuthLoginForm from '@pages/Auth/LoginPage/AuthLogin';
import AuthRegisterForm from '@pages/Auth/RegisterPage/AuthRegister';
import AuthForgetPasswordForm from '@pages/Auth/ForgetPasswordPage/AuthForgetPassword';
import { showErrorSnackbar, showSuccessSnacbar } from '@components/shared-layouts/Snackbar/helper'
import AuthContext from '@context/Auth/authContext'

interface AuthFormProps {
  page: string,
  handleFieldChange?: (fieldName: string, fieldValue: any) => void
}

function AuthForm({ page }: AuthFormProps) {

  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  
  const [searchParams] = useSearchParams()

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    // Handle form submission

    // Over here, we will get api function to be call and a function after all
    const apiToCall = callAuthFormApi(page);
    const actionAfterCall = getActionAfterSubmit(page, navigate);
    // Calling the api
    apiToCall(values)
      .then((res: any) => {
        // Reset the form after success
        actions.resetForm()
        // Enable the form
        actions.setSubmitting(false)
        showSuccessSnacbar(res?.message ?? 'Operation has been started')
        if (page === 'login') {
          const payload = {
            token: res.data.token,
            name: values.username
          }
          login(payload)
        }
        actionAfterCall();
        // Need to show the success modal
      }).catch((e: any) => {
        actions.setSubmitting(false);
        showErrorSnackbar(e?.error?.message ?? 'Operation could not be started')
      })
  }

  const formik = useFormik({
    initialValues: getAuthFormInitialValues(page),
    validationSchema: authFormValidationSchema[page],
    onSubmit: handleSubmit,
  })

  const getFormContent = (page: string) => {
    switch (page) {
      case 'login':
        return AuthLoginForm(formik)
      case 'register':
        return AuthRegisterForm(formik)
      case 'forgetPassowrd':
        return AuthForgetPasswordForm(formik)
      default:
        return AuthLoginForm(formik);
    }
  }
  
  
  useEffect(() => {
    const token = searchParams.get('token')
    const backendApiUrl = searchParams.get('backendApiUrl')
    
    if(token && backendApiUrl) {
      // Save it to the localstorage
      localStorage.setItem('token', token )
      localStorage.setItem('backendApiUrl', backendApiUrl)
      navigate('/projects')
    }
  },[searchParams, navigate])
  
  return (
    <>
      {getFormContent(page)}
    </>
  )
}

export default React.memo(AuthForm) 