import { useCallback, useMemo, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
} from "material-react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete, OpenInNew } from "@mui/icons-material";
import ProjectService, { deleteProject } from "@services/projectService";
import CreateNewProjectModal from "./createProjectModal";
import { CustomActionButton } from "@components/shared-ui-components/Buttons";
import ProjectContext from "@context/Project/projectContext";
import {
  showErrorSnackbar,
  showSuccessSnacbar,
} from "@components/shared-layouts/Snackbar/helper";

export type Project = {
  id: string;
  projectName: string;
  projectType: string;
  created_date: string;
  updated_date: string;
};

const ProjectsPage = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState<Project[]>([]);
  const projectContextInfo = useContext(ProjectContext);
  const { state: projectState, setState: setProjectState } = projectContextInfo;

  const [loading, setLoading] = useState<boolean>(false);

  console.log("project state", projectState);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    readASetProjects();
    // localStorage.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const readASetProjects = () => {
    ProjectService("getProjects", {}, projectContextInfo).then((x: any) => {
      const _rawProjects = x?.data?.projects ?? [];
      const _projects =
        _rawProjects?.map((each: any) => {
          return {
            id: each.project_id,
            projectType: each.project_type,
            projectName: each.project_name,
            created_date: each.created_time,
            updated_date: each.created_time,
          };
        }) ?? [];
      setTableData(_projects);
      setLoading(false);
    });
  };

  const handleCreateNewRow = (values: Project) => {
    tableData.push(values);
    setTableData([...tableData]);
  };

  const handleModalClose = () => {
    readASetProjects();
    setCreateModalOpen(false);
  };

  const handleDeleteRow = useCallback(
    (row: MRT_Row<Project>) => {
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue("id")}`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      console.log("sofjds", row.getValue("id"));
      deleteProject(row.getValue("id"))
        .then((resp) => {
          showSuccessSnacbar(
            `Project with ID ${row.getValue("id")} has been deleted`
          );
        })
        .catch((e) => showErrorSnackbar(e.error.message));
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );
  const handleOpenProject = async (row: MRT_Row<Project>) => {
    // Over here we need to handle the route
    const { id, projectName, projectType } = row.original;
    try {
      setProjectState({
        projectId: id,
        projectName,
        projectType,
      });
      localStorage.setItem("projectId", id);
      localStorage.setItem("projectName", projectName);
      localStorage.setItem("projectType", projectType);

      const projectTypeFormatted = projectType
        .toLowerCase()
        .split(" ")
        .join("_");

      navigate(`/${projectTypeFormatted}/operations/${id}/data-labelling`);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useMemo<MRT_ColumnDef<Project>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        // size: 80,
      },
      {
        accessorKey: "projectName",
        header: "Project Name",
        // size: 140
      },
      {
        accessorKey: "projectType",
        header: "Project Type",
        // size: 140,
      },
      {
        accessorKey: "created_date",
        header: "Created Date",
        // size: 140
      },
      {
        accessorKey: "updated_date",
        header: "Updated Date",
        // size: 140
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            // size: 120,
          },
        }}
        muiTablePaperProps={{ style: { width: "100%", margin: "2%" } }}
        columns={columns}
        data={tableData}
        enableColumnOrdering
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="top" title="Open">
              <IconButton
                color="success"
                onClick={() => handleOpenProject(row)}
              >
                <OpenInNew />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="top" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <CustomActionButton
            btnAction={() => setCreateModalOpen(true)}
            btnName="Create New Project"
          />
        )}
        state={{ isLoading: loading }}
      />
      <CreateNewProjectModal
        columns={columns}
        open={createModalOpen}
        onClose={handleModalClose}
        onSubmit={handleCreateNewRow}
      />
    </>
  );
};

export default ProjectsPage;
