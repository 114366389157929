/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import Cookies from 'js-cookie';

// import { authActions } from '@store/authReducer';
// import store from '@store/index';
// import { userActions } from '@store/userProfileReducer/index';

// type RequestType = null | undefined | string;


// const BASE_URL = '';

// class Serivce1 {
  
//   constructor () {
//     axios.defaults.withCredentials = true;  
//   }

//   getAuthToken = () => {
//     return Cookies.get('token') ? Cookies.get('token') : '';
//   };

//   getHeader = () => {
//     return {
//       'Content-Type': 'application/json;charset=UTF-8',
//       'Access-Control-Allow-Origin': '*',
//       Accept: 'application/json',
//       'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
//       'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
//       Authorization: `Bearer ${this.getAuthToken()}`
//     };
//   };

//   handleUnauthorized = () => {
//     Cookies.remove('token');
//     window.location.href = '/auth/login';
//   };

//   handleError = (err: { isAxiosError: boolean; response: any; message: string }) => {
//     console.error('=======> err: ', err)
//     console.error('======> err.response: ', err.response)
//     console.error('======> err.response : ', err.isAxiosError)
//     if (err.response && err.response.status === 401) {
//       this.handleUnauthorized();
//       return { status: 401, error: { message: 'Unauthorized' } };
//     }
//     if (err.response && err.response.status === 422) {
//       return { status: 422, error: { message: 'Unprocessable Entity' } };
//     }
//     if (err.isAxiosError && err.response) {
//       const errResponse = err.response;
//       return {
//         status: errResponse.status,
//         error: errResponse.data
//       };
//     }
//     if (err.message === 'canceled') {
//       return {
//         status: 451,
//         error: { message: err.message }
//       };
//     }
//     if (err) {
//       return {
//         status: 400,
//         error: { message: err.message }
//       };
//     }
//     return {
//       status: 400,
//       error: { message: 'Something went wrong!' }
//     };
//   };

//   get = async (url: string, basePath?: string | null, params?: any, controller?: any) => {
//     return new Promise((resolve, reject) => {
//       const headers = this.getHeader();

//       try {
//         let dataForMethod: any = { headers: headers };
//         if (params) {
//           dataForMethod = { ...dataForMethod, params: params };
//         }
//         if (controller) {
//           dataForMethod = {
//             ...dataForMethod,
//             signal: controller.signal
//           };
//         }
//         axios
//           .get(`${BASE_URL}${url}`, dataForMethod)
//           .then((res: any) => {
//             return resolve({ data: res.data, status: res.status });
//           })
//           .catch((err: any) => {
//             return reject(this.handleError(err));
//           });
//       } catch (err) {
//         return reject(err);
//       }
//     });
//   };


//   post = (url: string, params: {}, basePath?: string, controller?: any, addHeaders?: any) => {
//     return new Promise((resolve, reject) => {
//       const headers = this.getHeader();
//       let dataForMethod: any = { headers: { ...headers, ...addHeaders }};
//       if (controller) {
//         dataForMethod = {
//           ...dataForMethod,
//           signal: controller.signal
//         };
//       }

//       try {
        
//         console.log('this is just a test 2====>', params, dataForMethod)
//         axios
//           .post(`http://localhost:3005`, params, dataForMethod)
//           .then((res: { data: any; status: number }) => {
//             return resolve({
//               ...res.data,
//               data: res.data,
//               status: res.status,
//               success: res.status === 200
//             });
//           })
//           .catch((err: any) => {
//             return reject(this.handleError(err));
//           });
//       } catch (err) {
//         return reject(err);
//       }
//     });
//   };

//   delete = async (url: string, params?: any, controller?: any) => {
//     return new Promise((resolve, reject) => {
//       const headers = this.getHeader();

//       try {
//         let dataForMethod: any = { headers: headers };
//         if (params) {
//           dataForMethod = { ...dataForMethod };
//         }
//         if (controller) {
//           dataForMethod = {
//             ...dataForMethod,
//             signal: controller.signal
//           };
//         }

//         console.log('data for method ==>', dataForMethod)
//         axios
//           .delete(`${BASE_URL}${url}`, { data: params, headers })
//           .then((res: any) => {
//             return resolve({ data: res.data, status: res.status });
//           })
//           .catch((err: any) => {
//             return reject(this.handleError(err));
//           });
//       } catch (err) {
//         return reject(err);
//       }
//     });
//   };

// }

class Service {
  constructor() {
    // Set Axios global defaults
    axios.defaults.withCredentials = true; // Include cookies with all requests
    
    // Add a request interceptor to dynamically set the base URL
    axios.interceptors.request.use(
      (config) => {
        console.log('Interceptor is running ')
        config.baseURL = localStorage.getItem('backendApiUrl') ||  'https://test.com'; // Dynamically set the base URL
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  getAuthToken = () => {
    return localStorage.getItem('token') || '';
  };

  getHeader = () => {
    return {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
      Authorization: `Bearer ${this.getAuthToken()}`
    };
  };

  handleUnauthorized = () => {
    Cookies.remove('token');
    window.location.href = '/auth/login';
  };

  handleError = (err: { isAxiosError: boolean; response: any; message: string }) => {
    console.error('Error:', err);
    if (err.response && err.response.status === 401) {
      this.handleUnauthorized();
      return { status: 401, error: { message: 'Unauthorized' } };
    }
    if (err.response && err.response.status === 422) {
      return { status: 422, error: { message: 'Unprocessable Entity' } };
    }
    if (err.isAxiosError && err.response) {
      const errResponse = err.response;
      return {
        status: errResponse.status,
        error: errResponse.data
      };
    }
    if (err.message === 'canceled') {
      return {
        status: 451,
        error: { message: err.message }
      };
    }
    return {
      status: 400,
      error: { message: err.message || 'Something went wrong!' }
    };
  };

  get = async (url: string, params?: any, controller?: any) => {
    try {
      const response = await axios.get(url, {
        headers: this.getHeader(),
        params,
        signal: controller ? controller.signal : undefined
      });
      return { data: response.data, status: response.status };
    } catch (err:any) {
      throw this.handleError(err);
    }
  };

  post = async (url: string, params: {}, basePath?: string, controller?: any, addHeaders?: any) => {
    try {
      const response = await axios.post(url, params, {
        headers: { ...this.getHeader(), ...addHeaders },
        signal: controller ? controller.signal : undefined
      });
      return { data: response.data, status: response.status, success: response.status === 200 };
    } catch (err:any) {
      throw this.handleError(err);
    }
  };

  delete = async (url: string, params?: any, controller?: any) => {
    try {
      const response = await axios.delete(url, {
        headers: this.getHeader(),
        data: params,
        signal: controller ? controller.signal : undefined
      });
      return { data: response.data, status: response.status };
    } catch (err:any) {
      throw this.handleError(err);
    }
  };
}

export default new Service();
