// material-ui
import { Box, Grid } from '@mui/material';
import AuthCard from './AuthCard';
import BlueLogo from '@images/logo.webp'

// ==============================|| AUTHENTICATION - AUTH LAYOUT ||============================== //

const AuthLayout = () => (
  <Box sx={{ minHeight: '100vh' }}>
    <Grid
      container
      direction="column"
      justifyContent="center"
      sx={{
        minHeight: '100vh'
      }}
    >
      <Grid>
        <Box style={{ padding: '10px 20px', width: '240px', position: 'absolute', top: 10, right: 10 }}>
          <img style={{ width: '-webkit-fill-available' }} src={BlueLogo} alt='Logo' />
        </Box>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '80px' }}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
        >
          <Grid item>
            <AuthCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default AuthLayout;